import * as React from "react"
import Navbar from "../components/Navbar";
import Helmet from "react-helmet";
import { StaticImage } from "gatsby-plugin-image"
import Footer from "../components/Footer";
import Hero from "../components/Hero";

// markup
const AboutMeneerJansen = () => {
    return (
        <>
            <Helmet>
                <body className="page-over-meneer-jansen" />
            </Helmet>

            <Navbar/>

            <main>
                <div className="hero-wrapper">
                    <Hero title="Over Meneer Jansen" width="50"/>
                    <StaticImage src="../images/pexels-iva-691710.jpg" alt="Over meneer Jansen header" className="hero-image" />
                </div>

                <article>
                    <div className="content">
                        <div className="cols">
                            <div className="col-xs-100 col-sm-50 col-md-33">
                                <section>
                                    <StaticImage src="../images/mijnfoto.jpg" alt="Foto van mezelf"/>
                                </section>
                            </div>
                            <div className="col-xs-100 col-sm-50 col-md-66">
                                <section className="text-based">
                                    <p>
                                        Hi, ik ben Jeroen Jansen.
                                    </p>

                                    <p>
                                        Tussen 2008 en 2018 was ik een van de ontwikkelaars die Careweb mee heeft helpen ontwikkelen.
                                    </p>
                                    <p>
                                        Sinds 2019 werk ik voor mezelf en maak ik uitbreidingen op Careweb in de vorm van abonnementen en maatwerk oplossingen.
                                    </p>
                                    <p>
                                        Hiervoor probeer ik te begrijpen wat speelt in een zorg organisatie en hoe het proces er uit ziet. Van daaruit wil ik verbeteren
                                        waar mogelijk en het (administratieve) werk van de zorgverlener wegnemen of eenvoudiger maken.
                                    </p>
                                    <p>
                                        Ook geef ik trainingen in de Careweb ontwikkel toolkit.
                                    </p>
                                    <p>
                                        Careweb is een goed ontworpen EPD/zorgregistratie systeem dat veel ruimte biedt voor uitbreiding en koppelingen.
                                        Dit biedt ruimte om de aansluiting met de organisatie te verbeteren.
                                    </p>
                                    <h3 className="margin-top-2em">Achtergrond</h3>
                                    <p>
                                        Mijn achtergrond ligt in de Informatica. Met als studierichting: vormgeving en gebruikers interactie.
                                        Ik vind het raakvlak interessant van de mens met technologie.
                                        Met als uitdaging dit zo eenvoudig mogelijk te maken.
                                        Eigenlijk wilde ik na de middelbare school naar de kunstacademie.
                                        Maar na een omweg via het grafisch lyceum heb ik dus uiteindelijk informatica gekozen, en dan een combinatie van grafische vormgeving, gebruikerpsychologie en programmeren.
                                    </p>
                                </section>
                            </div>
                        </div>
                    </div>
                </article>

            </main>

            <Footer />
        </>

    )
}

export default AboutMeneerJansen
